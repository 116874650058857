import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'running',
    loadChildren: './running/running.module#RunningPageModule'
  },
  {
    path: 'projects',
    loadChildren: './projects/projects.module#ProjectsPageModule'
  },
  {
    path: 'hlct-fkt',
    loadChildren: () => import('./hlct-fkt/hlct-fkt.module').then( m => m.HlctFktPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
